import TTLCache from '@isaacs/ttlcache';
import { useEffect, useState } from 'react';

// the cache is available only while the navigation is handled by React,
// and is discarded on browser navigation
export const ttlCache = new TTLCache<string, any>({
  max: 10000,
  ttl: 1 * 60 * 60 * 1000, // 1 hour: we can invalidate the cache on reload
});

export type QueryFn<T> = () => Promise<T>;
export type QueryProps<T> = {
  queryKey: string;
  queryFn: QueryFn<T>;
  cacheOptions?: TTLCache.SetOptions;
};

export function cacheQuery<T>(props: QueryProps<T>): ReturnType<QueryFn<T>> {
  const { queryKey, queryFn, cacheOptions } = props;

  let promise: Promise<T>;
  if (ttlCache.has(queryKey)) {
    //console.debug('cache hit', queryKey);
    promise = ttlCache.get(queryKey) as Promise<T>;
  } else {
    //console.debug('cache miss', queryKey);
    promise = queryFn();
    ttlCache.set(queryKey, promise, cacheOptions);
  }

  return promise;
}

export function useCachedQuery<T>(props: QueryProps<T>): T | undefined {
  return usePromise(cacheQuery(props));
}

export function usePromise<T>(promise: Promise<T>): T | undefined;
export function usePromise<T>(promise: Promise<T>, initialState: T): T;
export function usePromise<T>(
  promise: Promise<T>,
  initialState?: T
): T | undefined {
  const [data, setData] = useState<T | undefined>(initialState);

  useEffect(() => {
    let ignore = false;
    promise.then((data) => {
      if (!ignore) {
        setData(data);
      }
    });

    return () => {
      ignore = true;
    };
  }, [promise]);

  return data;
}
