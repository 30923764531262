import './MarkdownContent.scss';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export type Props = {
  markdown: string;
};

export function MarkdownContent(props: Props) {
  const { markdown } = props;
  return (
    <div className="entry_content">
      <ReactMarkdown
        children={markdown}
        transformLinkUri={transformLinkUri}
        transformImageUri={transformImageUri}
      />
    </div>
  );
}

function transformLinkUri(href: string) {
  return upgradeLinkToHttps(href);
}

function transformImageUri(href: string) {
  href = upgradeLinkToHttps(href);

  // change size of images
  try {
    const url = new URL(href);
    url.pathname = url.pathname.replace(/\/(s288|s400)\//, '/s800/');
    return url.toString();
  } catch {
    return href;
  }
}

function upgradeLinkToHttps(href: string) {
  try {
    const url = new URL(href);
    if (url.protocol === 'http:') {
      url.protocol = 'https:';
    }

    return url.toString();
  } catch {
    return href;
  }
}
