import axios from 'axios';
import { baseURL } from './baseUrl';

export type User = {
  id: number;
  name: string;
};

export type UserTravel = {
  id: number;
  title: string;
  shortName: string;
  description: string;
  creationDate: string;
};

export type UserResponse = {
  user: User;
  travels: UserTravel[];
};

export async function fetchUser(userId: number): Promise<UserResponse> {
  const { data } = await axios.get(baseURL + `/api/users/${userId}`);
  return data;
}
