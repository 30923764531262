import {
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useNavigation,
  useOutletContext,
} from 'react-router-dom';

import { LoaderType } from '../clients/baseUrl';
import {
  CountryPosts,
  Travel,
  fetchTravel,
  fetchTravelItinerary,
} from '../clients/travels';

import { cacheQuery } from '../components/CacheProvider';
import { TravelHeader } from '../travels/TravelHeader';
import './$travel.tsx.scss';

export function makeTravelLink(shortName: string) {
  return `/${shortName}`;
}

export type TravelContext = {
  travel: Travel;
  countryPosts: CountryPosts[];
};

export async function fetchTravelContext(travelShortName: string) {
  const [travel, countryPosts] = await cacheQuery({
    queryKey: `travelcontext-${travelShortName}`,
    queryFn: () =>
      Promise.all([
        cacheQuery({
          queryKey: `travel-${travelShortName}`,
          queryFn: () => fetchTravel(travelShortName),
        }),
        cacheQuery({
          queryKey: `itinerary-${travelShortName}`,
          queryFn: () => fetchTravelItinerary(travelShortName),
        }),
      ]),
  });

  return {
    travel: travel,
    countryPosts: countryPosts,
  };
}

export async function loader({ params }: LoaderFunctionArgs) {
  const travelShortName = params.travel;
  const context = await fetchTravelContext(travelShortName!);
  return context;
}

export function useTravel() {
  return useOutletContext<TravelContext>();
}

export function WithTravelContext() {
  const context = useLoaderData() as LoaderType<typeof loader>;

  const navigation = useNavigation();
  return (
    <div className={navigation.state === 'idle' ? 'travel' : 'travel loading'}>
      <TravelHeader travel={context.travel} />
      <Outlet context={context} />
    </div>
  );
}
