import { To, useParams } from 'react-router-dom';

import { TextEntry } from '../clients/entries';
import { Photo } from '../clients/photos';

import { PhotoViewer } from '../entries/PhotoViewer';
import { useEntry } from './$travel.$country.$entry';

export function makePhotoLink(toEntry: To, photo: Photo) {
  return `${toEntry}/${photo.googlePhotoId}`;
}

export function PhotoPage() {
  const { photoId } = useParams();
  const { travel, country, entry, photos } = useEntry();

  const textEntry = entry as TextEntry;
  return (
    <PhotoViewer {...{ travel, country, entry: textEntry, photos, photoId }} />
  );
}
