import { Outlet } from 'react-router';
import { ScrollRestoration } from 'react-router-dom';

import { Menu } from './Menu';
import './App.scss';

export default function App() {
  return (
    <>
      <div className="App">
        <Header />
        <div className="content">
          {/* Hide menu for now */ false && <Menu />}
          <Outlet />
        </div>
        <Footer />
      </div>
      <ScrollRestoration />
    </>
  );
}

function Header() {
  return (
    <div className="header">
      <div className="header__title">Metoule up the World !</div>
    </div>
  );
}

function Footer() {
  return <div className="footer">&copy; Metoule 2009 - 2010</div>;
}
