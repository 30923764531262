import { To } from 'react-router-dom';
import { CountryChange, makeCountryChangeKey } from '../clients/entries';

import { getCountryName } from '../components/countries';
import { CountryEntryHeader } from '../entries/CountryEntryHeader';
import {
  EntryNavigationLinks,
  NavigationLink,
} from '../entries/EntryNavigationLinks';
import { TextEntries } from '../entries/TextEntries';

import { makeTravelLink } from './$travel';
import { useCountry } from './$travel.$country';
import './$travel.$country._index.scss';

export function makeCountryLink(toTravel: To, country: CountryChange) {
  const countryKey = makeCountryChangeKey(country);
  return `${toTravel}/${countryKey}`;
}

export function CountryPage() {
  const { travel, country, posts, previousCountry, nextCountry } = useCountry();

  const { title } = travel;
  const { code } = country;
  const countryName = getCountryName(code);
  document.title = `${countryName} | ${title} | Metoule up the World`;

  const toTravel = makeTravelLink(travel.shortName);
  return (
    <>
      <div className="country-change">
        <CountryEntryHeader
          user={travel.user}
          toTravel={toTravel}
          country={country}
        />
        <TextEntries
          toTravel={toTravel}
          country={country}
          entries={posts || []}
          showBackToTop={false}
        />
      </div>
      <EntryNavigationLinks
        previous={previousCountry && makeNavigationLink(previousCountry)}
        next={nextCountry && makeNavigationLink(nextCountry)}
      />
    </>
  );
}

function makeNavigationLink(country: CountryChange): NavigationLink {
  return {
    title: getCountryName(country.code),
    to: `../${makeCountryChangeKey(country)}`,
  };
}
