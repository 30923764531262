import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import './icons.css';
import './index.css';

import App from './application/App';
import ErrorPage from './error-page';
import { loader as travelsLoader, TravelsPage } from './routes/_index';
import {
  loader as profileLoader,
  UserProfilePage,
} from './routes/profile.$userId._index';
import { loader as travelLoader, WithTravelContext } from './routes/$travel';
import {
  loader as countryLoader,
  WithCountryContext,
} from './routes/$travel.$country';
import {
  loader as anyEntryLoader,
  WithEntryContext,
} from './routes/$travel.$country.$entry';
import { PhotoPage } from './routes/$travel.$country.$entry.$photoId';
import { AnyEntryPage } from './routes/$travel.$country.$entry._index';
import { CountryPage } from './routes/$travel.$country._index';
import { TravelIndexPage } from './routes/$travel._index';
import { LegacyPhotoPage } from './routes/$travel.photo';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <TravelsPage />,
          loader: travelsLoader,
        },
        {
          path: ':travel',
          element: <WithTravelContext />,
          loader: travelLoader,
          children: [
            {
              element: <TravelIndexPage />,
              index: true,
            },
            {
              path: ':country',
              element: <WithCountryContext />,
              loader: countryLoader,
              children: [
                {
                  index: true,
                  element: <CountryPage />,
                },
                {
                  path: ':entry',
                  element: <WithEntryContext />,
                  loader: anyEntryLoader,
                  children: [
                    { index: true, element: <AnyEntryPage /> },
                    { path: ':photoId', element: <PhotoPage /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: ':travel/photo',
          element: <WithTravelContext />,
          loader: travelLoader,
          children: [
            {
              index: true,
              element: <LegacyPhotoPage />,
            },
          ],
        },
        {
          path: 'profile/:userId',
          element: <UserProfilePage />,
          loader: profileLoader,
          children: [{ index: true, path: 'index', element: <></> }],
        },
      ],
    },
  ],
  { basename: '/' }
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
