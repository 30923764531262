import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { cacheQuery } from '../components/CacheProvider';

import { LoaderType } from '../clients/baseUrl';
import { fetchUser } from '../clients/users';

import { ProfileView } from '../profile/ProfileView';

export function makeUserProfileLink(userId: number) {
  return `/profile/${userId}`;
}

export async function loader({ params }: LoaderFunctionArgs) {
  const userId = parseInt(params.userId!, 10);
  if (isNaN(userId)) {
    throw new Response(null, {
      status: 400,
      statusText: 'User ID is not a number',
    });
  }

  const userResponse = await cacheQuery({
    queryKey: `user-${userId}`,
    queryFn: () => fetchUser(userId),
  });

  return userResponse;
}

export function UserProfilePage() {
  const { user, travels } = useLoaderData() as LoaderType<typeof loader>;
  return <ProfileView user={user} travels={travels} />;
}
