import axios from 'axios';
import { baseURL } from './baseUrl';
import { CountryChange, TextEntry } from './entries';
import { User } from './users';

export type Travel = {
  id: number;
  user: User;
  title: string;
  shortName: string;
  description: string;
};

export type CountryPosts = {
  country: CountryChange;
  posts: TextEntry[];
};

type TravelResponse = {
  travels: Travel[];
  countries: { [travelId: number]: CountryChange[] };
};

export async function fetchTravels() {
  const { data } = await axios.get<TravelResponse>(baseURL + '/api/travels');
  return data;
}

export async function fetchTravel(shortName: string) {
  const { data } = await axios.get<Travel>(
    baseURL + `/api/travels/${shortName}`
  );
  return data;
}

export async function fetchTravelItinerary(shortName: string) {
  const { data } = await axios.get<CountryPosts[]>(
    baseURL + `/api/travels/${shortName}/itinerary`
  );
  return data;
}
