import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';

import { Comment, findEntityComments } from '../clients/comments';

import { makeUserProfileLink } from '../routes/profile.$userId._index';

import './Comments.scss';
import { formatEntryDate } from './TextEntryLink';
import { cacheQuery } from '../components/CacheProvider';
import { DeferLoading } from '../components/Loading';

export type Props = {
  travelShortName: string;
  comments?: Promise<Comment[]>;
  entityType: 'entry' | 'photo';
  entityId: string | number;
};

export function Comments(props: Props) {
  const { comments } = props;

  return comments !== undefined ? (
    <CommentsView comments={comments} />
  ) : (
    <DeferedCommentsView {...props} />
  );
}

function CommentsView(props: { comments: NonNullable<Props['comments']> }) {
  const { comments } = props;
  return (
    <>
      <DeferLoading what="comments" resolve={comments}>
        {(comments) => <LoadedCommentsView comments={comments} />}
      </DeferLoading>
      {/* Disable all comments for now */ true && <CommentForm />}
    </>
  );
}

function LoadedCommentsView(props: { comments: Comment[] }) {
  const { comments } = props;
  return (
    <>
      {comments.length > 0 && (
        <div className="entry_comments" id="all_comments">
          {comments.map((comment) => (
            <div className="entry_comment" key={comment.who + comment.date}>
              <span className="comment_author">
                {comment.who !== undefined ? (
                  comment.who
                ) : comment.user !== undefined ? (
                  <Link to={makeUserProfileLink(comment.user.id)}>
                    {comment.user.name}
                  </Link>
                ) : (
                  'Anonymous'
                )}
              </span>
              <span className="datetime">{formatEntryDate(comment.date)}</span>
              <div
                className="comment_text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment.content),
                }}
              ></div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function DeferedCommentsView(props: {
  travelShortName: Props['travelShortName'];
  entityType: Props['entityType'];
  entityId: Props['entityId'];
}) {
  const { travelShortName, entityType, entityId } = props;
  const comments = cacheQuery({
    queryKey: `${entityType}comments/${travelShortName}/${entityId}`,
    queryFn: () => findEntityComments(travelShortName, entityType, entityId),
    cacheOptions: {
      ttl: 60 * 1000, // 1 minute, so that comments can be reloaded faster
    },
  });

  return <CommentsView {...props} comments={comments} />;
}

function CommentForm() {
  return (
    <div className="comment_form">
      <h2>Post comment</h2>
      <form>
        <fieldset>
          <legend>Name</legend>
          <input type="text" name="comment_username"></input>
        </fieldset>
        <fieldset>
          <legend>Comment</legend>
          <textarea
            rows={10}
            cols={40}
            name="comment_content"
            placeholder="Write a comment..."
          ></textarea>
        </fieldset>
        <fieldset>
          <button
            className="comment_submit"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Post comment
          </button>
        </fieldset>
      </form>
    </div>
  );
}
