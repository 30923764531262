import {
  LoaderFunctionArgs,
  Outlet,
  defer,
  useLoaderData,
  useOutletContext,
} from 'react-router-dom';

import { LoaderType } from '../clients/baseUrl';
import { Comment, findEntityComments } from '../clients/comments';
import { CountryChange, Entry, makeCountryChangeKey } from '../clients/entries';
import { Photo, findEntryPhotos } from '../clients/photos';

import { cacheQuery } from '../components/CacheProvider';
import { fetchTravelContext } from './$travel';
import { CountryContext, useCountry } from './$travel.$country';
import './$travel.tsx.scss';

type EntryResponse = {
  entry: Entry;
  photos: Promise<Photo[]>;
  comments: Promise<Comment[]>;

  previousEntryCountry: CountryChange | undefined;
  previousEntry: Entry | undefined;

  nextEntryCountry: CountryChange | undefined;
  nextEntry: Entry | undefined;
};

type EntryContext = CountryContext & EntryResponse;

export async function loader({ params }: LoaderFunctionArgs) {
  const travelShortName = params.travel!;
  const countryCode = params.country!;
  const entryShortName = params.entry!;

  const { countryPosts } = await fetchTravelContext(travelShortName);

  for (let i = 0; i < countryPosts.length; i++) {
    const { country, posts } = countryPosts[i];
    const countryKey = makeCountryChangeKey(country);
    if (countryCode !== countryKey) {
      continue;
    }

    let entry: Entry | undefined;
    let previousEntry: Entry | undefined;
    let previousEntryCountry: CountryChange | undefined;
    let nextEntry: Entry | undefined;
    let nextEntryCountry: CountryChange | undefined;

    const nextCountry = countryPosts[i + 1]?.country;
    if (entryShortName === country.shortName) {
      // the requested entry is the country's entry
      entry = country;

      // if the previous country has no posts, use the previous country as the previous entry
      previousEntryCountry = countryPosts[i - 1]?.country;
      previousEntry =
        countryPosts[i - 1]?.posts?.at(-1) ?? previousEntryCountry;

      // if the current country has no posts, use the next country as the next entry
      nextEntry = countryPosts[i]?.posts?.[0] ?? nextCountry;
      nextEntryCountry =
        countryPosts[i]?.posts?.[0] !== undefined ? country : nextCountry;
    } else if (posts !== undefined) {
      // the requested entry is one of the country's posts
      for (let j = 0; j < posts.length; j++) {
        const post = posts[j];
        if (entryShortName !== post.shortName) {
          continue;
        }

        entry = post;

        previousEntryCountry = country;
        previousEntry = posts[j - 1] ?? country;

        nextEntryCountry = posts[j + 1] !== undefined ? country : nextCountry;
        nextEntry = posts[j + 1] ?? countryPosts[i + 1]?.country;
        break;
      }
    }

    if (entry !== undefined) {
      const photosPromise = cacheQuery({
        queryKey: `photos/${travelShortName}/${entry.id}`,
        queryFn: () => findEntryPhotos(travelShortName, entry!.id),
      });

      const commentsPromise = cacheQuery({
        queryKey: `entrycomments/${travelShortName}/${entry.id}`,
        queryFn: () => findEntityComments(travelShortName, 'entry', entry!.id),
      });

      return defer({
        entry,
        photos: photosPromise,
        comments: commentsPromise,
        previousEntryCountry,
        previousEntry,
        nextEntryCountry,
        nextEntry,
      });
    }
  }

  throw new Response(null, { status: 404, statusText: 'Entry not found' });
}

export function useEntry() {
  return useOutletContext<EntryContext>();
}

export function WithEntryContext() {
  const countryContext = useCountry();
  const entryResponse = useLoaderData() as LoaderType<typeof loader>;

  // we re-export the country context, so that we can keep using the useCountry hook
  return <Outlet context={{ ...countryContext, ...entryResponse }} />;
}
