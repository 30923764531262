import { useEffect } from 'react';
import { Link, To, useLocation } from 'react-router-dom';

import { Comment } from '../clients/comments';
import {
  CountryChange,
  Entry,
  TextEntry,
  makeEntryTitle,
} from '../clients/entries';
import { Photo } from '../clients/photos';
import { Travel } from '../clients/travels';

import { KnownIcons, LinkWithIcon } from '../components/LinkWithIcon';
import { DeferLoading } from '../components/Loading';

import { Comments } from './Comments';
import { CountryEntryHeader } from './CountryEntryHeader';
import { CountryLink } from './CountryLink';
import { EntryNavigationLinks, NavigationLink } from './EntryNavigationLinks';
import { MarkdownContent } from './MarkdownContent';
import { formatEntryDate } from './TextEntryLink';

import { makeTravelLink } from '../routes/$travel';
import { makeEntryLink } from '../routes/$travel.$country.$entry._index';
import { makeCountryLink } from '../routes/$travel.$country._index';
import './EntryView.scss';

export type Props = {
  travel: Travel;
  country: CountryChange;
  entry: Entry;

  photos: Promise<Photo[]>;
  comments: Promise<Comment[]>;

  previousEntry?: Entry;
  previousEntryCountry?: CountryChange;

  nextEntry?: Entry;
  nextEntryCountry?: CountryChange;
};

export function EntryView(props: Props) {
  const {
    travel,
    country,
    entry,
    photos,
    comments,
    previousEntry,
    previousEntryCountry,
    nextEntry,
    nextEntryCountry,
  } = props;

  const { shortName, user } = travel;

  const { hash, key } = useLocation();
  useEffect(() => {
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      targetElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [key, hash]);

  const toTravel = makeTravelLink(shortName);
  return (
    <>
      {entry.code === undefined ? (
        <TextEntryView
          toTravel={toTravel}
          country={country}
          entry={entry}
          photos={photos}
          comments={comments}
        />
      ) : (
        <CountryEntryHeader toTravel={toTravel} country={country} user={user} />
      )}
      <EntryNavigationLinks
        previous={
          previousEntryCountry &&
          previousEntry &&
          makeNavigationLink(toTravel, previousEntryCountry, previousEntry)
        }
        next={
          nextEntryCountry &&
          nextEntry &&
          makeNavigationLink(toTravel, nextEntryCountry, nextEntry)
        }
      />
      <Comments
        travelShortName={shortName}
        entityType="entry"
        entityId={entry.id}
        comments={comments}
      />
    </>
  );
}

function TextEntryView(props: {
  toTravel: To;
  country: Props['country'];
  entry: TextEntry;
  photos: Props['photos'];
  comments: Props['comments'];
}) {
  const { toTravel, country, entry, photos, comments } = props;
  return (
    <>
      <div className="travel-entry-view">
        <TextEntryHeader toTravel={toTravel} country={country} entry={entry} />
        <MarkdownContent markdown={entry.content ?? ''} />
        <div className="entry_footer">
          <DeferedLinkWithCount
            to="#all_photos"
            icon="icon_photos"
            promise={photos}
            title="photos"
          />
          <DeferedLinkWithCount
            to="#all_comments"
            icon="icon_comments"
            promise={comments}
            title="comments"
          />
        </div>
      </div>
      <EntryPhotos {...props} />
    </>
  );
}

export function TextEntryHeader(props: {
  toTravel: To;
  country: CountryChange;
  entry: TextEntry;
  onTitleClick?: () => void;
}) {
  const { toTravel, country, entry, onTitleClick } = props;
  return (
    <div className="entry_header">
      <CountryLink toTravel={toTravel} country={country} />
      <div>
        <div
          className="entry_title"
          onClick={onTitleClick}
          style={onTitleClick ? { cursor: 'pointer' } : undefined}
        >
          {entry.title}
        </div>
        <div className="datetime">{formatEntryDate(entry.date)}</div>
      </div>
    </div>
  );
}

function makeNavigationLink(
  toTravel: To,
  country: CountryChange,
  entry: Entry
): NavigationLink {
  const toCountry = makeCountryLink(toTravel, country);
  return {
    title: makeEntryTitle(entry),
    to: makeEntryLink(toCountry, entry),
  };
}

function EntryPhotos(props: { photos: Props['photos'] }) {
  const { photos } = props;

  return (
    <div className="entry_photos" id="all_photos">
      <DeferLoading what="photos" resolve={photos}>
        {(photos) => (
          <>
            {photos.map((photo) => (
              <Link to={photo.googlePhotoId} key={photo.url}>
                <img src={photo.url} alt={photo.caption} />
              </Link>
            ))}
          </>
        )}
      </DeferLoading>
    </div>
  );
}

function DeferedLinkWithCount(props: {
  to: To;
  promise: Promise<any[]>;
  icon: KnownIcons;
  title: string;
}) {
  const { to, promise, icon, title } = props;
  return (
    <LinkWithIcon to={to} icon={icon}>
      <DeferLoading
        what={title}
        resolve={promise}
        fallback={`... ${title}`}
        errorElement={`??? ${title}`}
      >
        {(items) => `${items.length} ${title}`}
      </DeferLoading>
    </LinkWithIcon>
  );
}
