import { useLoaderData } from 'react-router';

import { fetchTravels } from '../clients/travels';
import { cacheQuery } from '../components/CacheProvider';
import { TravelHeader } from '../travels/TravelHeader';
import { TravelView } from '../travels/TravelView';

export async function loader() {
  const { travels, countries } = await cacheQuery({
    queryKey: 'travels',
    queryFn: fetchTravels,
  });

  return {
    travels: travels,
    countries: countries,
  };
}

export function TravelsPage() {
  const { travels, countries } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;

  return (
    <>
      {travels.map((travel) => (
        <div key={travel.id}>
          <TravelHeader travel={travel} />
          <TravelView
            key={travel.id}
            travel={travel}
            showMap={true}
            countryPosts={countries[travel.id].map((c) => ({
              country: c,
              posts: [],
            }))}
          />
        </div>
      ))}
    </>
  );
}
