import axios from 'axios';
import { baseURL } from './baseUrl';
import { CountryChange, TextEntry } from './entries';

export type Photo = {
  googlePhotoId: string;
  url: string;
  caption: string | undefined;
  date: string;
};

export type PhotoEntry = {
  countryChange: CountryChange;
  entry: TextEntry;
  photo: Photo;
};

export async function findEntryPhotos(
  travelShortName: string,
  entryId: number
) {
  const { data } = await axios.get<Photo[]>(
    baseURL + `/api/photos/${travelShortName}/entry/${entryId}`
  );
  return data;
}

export async function findEntryForPhoto(
  travelShortName: string,
  googlePhotoId: string
) {
  const { data } = await axios.get<PhotoEntry>(
    baseURL + `/api/photos/${travelShortName}/findentryfor/${googlePhotoId}`
  );
  return data;
}
