import axios from 'axios';
import { baseURL } from './baseUrl';

export type MeanOfTransport = 'PLANE' | 'TRAIN' | 'BOAT' | 'ROAD';

export type Location = {
  id: number;
  locationDateTime: string;
  latitude: number;
  longitude: number;
  place: string;
  meanOfTransport: MeanOfTransport;
};

export async function fetchTravelLocations(travelShortName: string) {
  const { data } = await axios.get<Location[]>(
    baseURL + `/api/locations/${travelShortName}`
  );
  return data;
}
