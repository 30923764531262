import { To } from 'react-router-dom';

import { CountryChange, makeCountryChangeKey } from '../clients/entries';
import { CountryPosts, Travel } from '../clients/travels';

import { CountryLink } from '../entries/CountryLink';
import { CountryList } from './CountryList';
import { TravelMap } from './TravelMap';

import { makeTravelLink } from '../routes/$travel';
import './TravelView.scss';

export type Props = {
  travel: Travel;
  countryPosts: CountryPosts[];
  showMap?: boolean;
  showFlags?: boolean;
  showEntries?: boolean;
};

export function TravelView(props: Props) {
  const { travel, countryPosts, showMap, showFlags, showEntries } = props;
  const { shortName, description } = travel;

  const toTravel = makeTravelLink(shortName);
  const countries = countryPosts.map((countryPost) => countryPost.country);
  return (
    <div className="travel_body">
      <div className="description">{description}</div>
      {showMap && <TravelMap travelShortName={shortName} />}
      {showFlags && countries && (
        <AllFlags toTravel={toTravel} countries={countries} />
      )}
      <CountryList
        toTravel={toTravel}
        countryPosts={countryPosts}
        showEntries={showEntries}
      />
    </div>
  );
}

function AllFlags(props: { toTravel: To; countries: CountryChange[] }) {
  const { toTravel, countries } = props;
  return (
    <div className="travel_countries_summary">
      {countries.map((country) => (
        <CountryLink
          toTravel={toTravel}
          country={country}
          key={makeCountryChangeKey(country)}
        />
      ))}
    </div>
  );
}
