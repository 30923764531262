import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLoaderData,
  useOutletContext,
} from 'react-router-dom';

import { LoaderType } from '../clients/baseUrl';
import {
  CountryChange,
  TextEntry,
  makeCountryChangeKey,
} from '../clients/entries';
import { Travel } from '../clients/travels';

import { fetchTravelContext } from './$travel';
import './$travel.tsx.scss';

export type CountryContext = {
  travel: Travel;
  country: CountryChange;
  posts: TextEntry[] | undefined;
  previousCountry: CountryChange | undefined;
  nextCountry: CountryChange | undefined;
};

export async function loader({ params }: LoaderFunctionArgs) {
  const travelShortName = params.travel;
  const countryCode = params.country;

  const { travel, countryPosts } = await fetchTravelContext(travelShortName!);

  for (let i = 0; i < countryPosts.length; i++) {
    const { country, posts } = countryPosts[i];
    const countryKey = makeCountryChangeKey(country);
    if (countryCode === countryKey) {
      const previousCountry = countryPosts[i - 1]?.country;
      const nextCountry = countryPosts[i + 1]?.country;
      const countryContext: CountryContext = {
        travel,
        country,
        posts,
        previousCountry,
        nextCountry,
      };

      return countryContext;
    }

    // redirect if the requested countryCode is in fact an the country's entry shortName
    if (countryCode === country.shortName) {
      return redirect(`../${countryKey}/${country.shortName}`);
    }

    // redirect if the requested countryCode is in fact one of the  country's entry
    if (posts !== undefined) {
      for (let j = 0; j < posts.length; j++) {
        const post = posts[j];
        if (countryCode === post.shortName) {
          return redirect(`../${countryKey}/${post.shortName}`);
        }
      }
    }
  }

  throw new Response(null, {
    status: 404,
    statusText: `Country not Found: ${countryCode}`,
  });
}

export function useCountry() {
  return useOutletContext<CountryContext>();
}

export function WithCountryContext() {
  const context = useLoaderData() as LoaderType<typeof loader>;
  return <Outlet context={context} />;
}
