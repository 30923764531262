import { Link } from 'react-router-dom';
import { Travel } from '../clients/travels';

import { makeUserProfileLink } from '../routes/profile.$userId._index';
import { makeTravelLink } from '../routes/$travel';
import './TravelHeader.scss';

export type Props = {
  travel: Travel;
};
export function TravelHeader(props: Props) {
  const { travel } = props;
  const { title, shortName, user } = travel;
  return (
    <h2 className="travel-header">
      <Link to={makeTravelLink(shortName)} className="travel_name">
        {title}
      </Link>{' '}
      <span className="travel_owner">
        by <Link to={makeUserProfileLink(user.id)}>{user.name}</Link>
      </span>
    </h2>
  );
}
