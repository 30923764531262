import { To } from 'react-router-dom';
import { Entry, makeEntryTitle } from '../clients/entries';
import { EntryView } from '../entries/EntryView';
import { useEntry } from './$travel.$country.$entry';

export function makeEntryLink(toCountry: To, entry: Entry) {
  return `${toCountry}/${entry.shortName}`;
}

export function AnyEntryPage() {
  const entryContext = useEntry();

  const { travel, entry } = entryContext;
  const { title } = travel;
  const entryTitle = makeEntryTitle(entry);
  document.title = `${entryTitle} | ${title} | Metoule up the World`;

  return <EntryView {...entryContext} />;
}
