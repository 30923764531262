import { To } from 'react-router';

import { makeCountryChangeKey } from '../clients/entries';
import { CountryPosts } from '../clients/travels';

import { CountryLink } from '../entries/CountryLink';
import { TextEntries } from '../entries/TextEntries';
import './CountryList.scss';

type CountryListProps = {
  toTravel: To;
  countryPosts: CountryPosts[];

  /** If true, show the entries of each country; otherwise, just show the countries */
  showEntries?: boolean;
};

export function CountryList(props: CountryListProps) {
  const { toTravel, countryPosts, showEntries } = props;
  return (
    <ul className="countries">
      {countryPosts.map(({ country, posts }) => (
        <li key={makeCountryChangeKey(country)}>
          <CountryLink toTravel={toTravel} country={country} showName={true} />
          {showEntries && (
            <TextEntries
              toTravel={toTravel}
              country={country}
              entries={posts}
              showBackToTop={true}
            />
          )}
        </li>
      ))}
    </ul>
  );
}
