import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CountryChange, TextEntry } from '../clients/entries';
import { Photo } from '../clients/photos';
import { Travel } from '../clients/travels';

import { TravelHeader } from '../travels/TravelHeader';
import { Comments } from './Comments';
import { TextEntryHeader } from './EntryView';
import { formatEntryDate } from './TextEntryLink';

import { makeTravelLink } from '../routes/$travel';
import './PhotoViewer.scss';
import { usePromise } from '../components/CacheProvider';

export type Props = {
  travel: Travel;
  country: CountryChange;
  entry: TextEntry;
  photos: Promise<Photo[]>;
  photoId: string | undefined;
};

export function PhotoViewer(props: Props) {
  const { travel, photos: promise, photoId } = props;
  const { shortName: travelShortName } = travel;

  const photos = usePromise(promise, []);

  const photoIdx = photos.findIndex((p) => p.googlePhotoId === photoId);

  const navigate = useNavigate();
  const navigateToPhoto = (idx: number) =>
    navigate('../' + photos[idx].googlePhotoId);

  const hasPreviousPhoto = photoIdx > 0;
  const showPreviousPhoto = () => {
    hasPreviousPhoto && navigateToPhoto(photoIdx - 1);
  };

  const hasNextPhoto = photoIdx < photos.length - 1;
  const showNextPhoto = () => {
    hasNextPhoto && navigateToPhoto(photoIdx + 1);
  };

  const navigateToEntry = () => {
    navigate('..');
  };

  const photo = photos[photoIdx];

  // force focus on the div to enable keyboard navigation
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current && ref.current.focus();
  }, []);

  return (
    <div
      className="photo-viewer"
      ref={ref}
      tabIndex={-1}
      onKeyDown={(e) => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
          return;
        }

        if (e.key === 'ArrowLeft') {
          showPreviousPhoto();
        } else if (e.key === 'ArrowRight') {
          showNextPhoto();
        } else if (e.key === 'Escape') {
          navigateToEntry();
        }
      }}
    >
      <div className="photo-viewer-content">
        <div className="photo">
          <ShowPhoto photo={photo} />
          {hasPreviousPhoto && (
            <div className="show_previous_photo" onClick={showPreviousPhoto} />
          )}
          {hasNextPhoto && (
            <div className="show_next_photo" onClick={showNextPhoto} />
          )}
        </div>
        <div className="photo_comments">
          <h2 className="photo_comments_header">Photos of the world!</h2>
          <TravelHeader travel={travel} />
          <TextEntryHeader
            toTravel={makeTravelLink(travel.shortName)}
            onTitleClick={navigateToEntry}
            {...props}
          />
          {photo && (
            <Comments
              travelShortName={travelShortName}
              entityType="photo"
              entityId={photo.googlePhotoId}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ShowPhoto(props: { photo: Photo | undefined }) {
  const { photo } = props;
  if (photo === undefined) {
    return <>Loading...</>;
  }

  const { url, caption, date } = photo;
  const src = url.replace(/\/(s288|s400|s800)\//, '/s1600/');

  return (
    <>
      <img src={src} alt={caption} />
      <div className="photo_caption">
        {caption && <span className="photo_title">{caption}</span>}
        <span className="datetime">{formatEntryDate(date)}</span>
      </div>
    </>
  );
}
