import { TravelView } from '../travels/TravelView';
import { useTravel } from './$travel';

export function TravelIndexPage() {
  const { travel, countryPosts } = useTravel();

  const { title } = travel;
  document.title = `${title} | Metoule up the World`;

  return (
    <TravelView
      travel={travel}
      countryPosts={countryPosts}
      showMap={true}
      showFlags={true}
      showEntries={true}
    />
  );
}
