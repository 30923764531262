import { CountryCode, getCountryName } from '../components/countries';

type SharedEntryData = {
  id: number;
  date: string;
  shortName: string;
  code?: CountryCode | undefined;
};

export type CountryChange = SharedEntryData & {
  title?: undefined;
  code: CountryCode;
  visitNumber?: string;
};

export type TextEntry = SharedEntryData & {
  title: string;
  code?: undefined;
  content: string; // markdown
};

export type Entry = CountryChange | TextEntry;

export function makeCountryChangeKey(countryChange: CountryChange): string {
  return countryChange.code + (countryChange.visitNumber ?? '');
}

export function makeEntryTitle(entry: Entry): string {
  return entry.title ?? `Entering ${getCountryName(entry.code)}`;
}
