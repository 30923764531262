import { HTMLAttributes, Suspense } from 'react';
import { Await, AwaitProps } from 'react-router-dom';

export type Props<T> = {
  what: string;
  resolve: Promise<T>;
  children: React.ReactNode | ((data: T) => React.ReactNode);
  fallback?: React.SuspenseProps['fallback'];
  errorElement?: AwaitProps['errorElement'];
};

export function DeferLoading<T>(props: Props<T>) {
  const { what, resolve, children, fallback, errorElement } = props;
  return (
    <Suspense fallback={fallback ?? <p className="loader"></p>}>
      <Await
        resolve={resolve}
        errorElement={errorElement ?? <p>Error loading {what}!</p>}
        children={children}
      ></Await>
    </Suspense>
  );
}
