import { Status, Wrapper } from '@googlemaps/react-wrapper';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { fetchTravelLocations, Location } from '../clients/locations';
import { Travel } from '../clients/travels';

import { useCachedQuery } from '../components/CacheProvider';
import { formatEntryDate } from '../entries/TextEntryLink';
import './TravelMap.scss';

export type Props = {
  travelShortName: Travel['shortName'];
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

export function TravelMap(props: Props) {
  const { travelShortName } = props;
  const wrapper = useMemo(
    () => (
      <Wrapper
        apiKey={googleMapsApiKey}
        render={(status) => renderMap(status, travelShortName)}
        libraries={['marker']}
      />
    ),
    [travelShortName]
  );

  return wrapper;
}

const renderMap = (status: Status, travelShortName: string) => {
  switch (status) {
    case Status.LOADING:
      return <div className="travel_map loading" />;
    case Status.FAILURE:
      return <div className="travel_map failed" />;
    case Status.SUCCESS:
      return <TravelMapComponent travelShortName={travelShortName} />;
  }
};

function TravelMapComponent(props: Props) {
  const { travelShortName } = props;

  // split the initialization of the map into two steps: one to load the map and the other to add the markers
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  useEffect(() => {
    const center = { lat: 48.857, lng: 2.294 };
    const zoom = 1.3;

    const mapOptions: google.maps.MapOptions = {
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
      mapId: '5a8f94f07e28d340',
      center,
      zoom,
    };

    setMap(
      new window.google.maps.Map(ref.current as HTMLDivElement, mapOptions)
    );
  }, []);

  const locations = useCachedQuery({
    queryKey: `locations-${travelShortName}`,
    queryFn: () => fetchTravelLocations(travelShortName!),
  });

  useEffect(() => {
    if (map === undefined || locations === undefined) {
      return;
    }

    try {
      addItineraryToMap(map, locations);
    } catch (e) {
      console.log('Failed to render itinerary', e);
      ref.current?.classList.add('failed');
    }
  }, [map, locations]);

  return <div ref={ref} className="travel_map" />;
}

function addItineraryToMap(map: google.maps.Map, locations: Location[]) {
  const infoWindow = new google.maps.InfoWindow();
  const itinerary: google.maps.LatLngLiteral[] = [];
  for (let location of locations) {
    const placeLabel = new window.google.maps.marker.PinElement({
      background: '#b4cff0',
      borderColor: '#8796dc',
      glyphColor: '#8796dc',
      scale: 0.7,
    });

    const position = { lat: location.latitude, lng: location.longitude };
    itinerary.push(position);

    const marker = new window.google.maps.marker.AdvancedMarkerElement({
      map,
      position: position,
      title: location.place,
      content: placeLabel.element,
    });

    // create the content of the info window
    const element = document.createElement('div');
    element.className = 'travel_map_marker';

    const content = (
      <>
        <div className={`icon icon_${location.meanOfTransport}`}></div>
        <div className="location_details">
          <h4>{location.place}</h4>
          <span className="datetime">
            {formatEntryDate(location.locationDateTime)}
          </span>
        </div>
      </>
    );

    const tooltipContainer = createRoot(element);
    tooltipContainer.render(content);

    // open info window on click
    marker.addListener('click', () => {
      infoWindow.close();
      infoWindow.setContent(element);
      infoWindow.open(marker.map, marker);
    });
  }

  // draw the full itinerary
  new google.maps.Polyline({
    path: itinerary,
    geodesic: true,
    strokeColor: '#D9381E',
    strokeOpacity: 1.0,
    strokeWeight: 2,
    map: map,
  });
}
