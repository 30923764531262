import axios from 'axios';
import { User } from './users';
import { baseURL } from './baseUrl';

export type Comment = {
  content: string;
  date: string;
  who: string | undefined;
  user: User | undefined;
};

export async function findEntityComments(
  travelShortName: string,
  entityType: 'entry' | 'photo',
  entityId: number | string
): Promise<Comment[]> {
  const { data } = await axios.get(
    baseURL + `/api/comments/${travelShortName}/${entityType}/${entityId}`
  );
  return data;
}
