import { To } from 'react-router';

import { CountryChange } from '../clients/entries';

import { LinkWithCountryFlag } from '../components/LinkWithIcon';
import { getCountryName } from '../components/countries';

import { makeCountryLink } from '../routes/$travel.$country._index';
import './CountryLink.scss';

export type Props = {
  toTravel: To;
  country: CountryChange;
  showName?: boolean;
};

export function formatCountryChangeDate(date: string) {
  return new Date(date).toLocaleDateString('en-GB', { dateStyle: 'full' });
}

export function CountryLink(props: Props) {
  const { toTravel, country, showName } = props;
  const { code: countryCode, date: arrivalDate } = country;

  const countryName = getCountryName(countryCode);

  return (
    <LinkWithCountryFlag
      className="link_to_country"
      countryCode={countryCode}
      to={makeCountryLink(toTravel, country)}
      title={!showName ? countryName : undefined}
    >
      {showName && (
        <>
          {countryName}
          {countryCode !== 'A0' && (
            <span className="arrived_on datetime">
              arrived on {formatCountryChangeDate(arrivalDate)}
            </span>
          )}
        </>
      )}
    </LinkWithCountryFlag>
  );
}
