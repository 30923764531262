import { useLocation, useNavigate } from 'react-router-dom';
import { makeTravelLink, useTravel } from './$travel';
import { findEntryForPhoto } from '../clients/photos';
import { useEffect } from 'react';
import { makeCountryLink } from './$travel.$country._index';
import { makeEntryLink } from './$travel.$country.$entry._index';
import { makePhotoLink } from './$travel.$country.$entry.$photoId';

export function LegacyPhotoPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const { travel } = useTravel();
  const { shortName } = travel;

  useEffect(() => {
    const toTravel = makeTravelLink(shortName);
    const photoId = location.hash.substring(1);
    findEntryForPhoto(shortName, photoId)
      .then((photoEntry) => {
        const { countryChange, entry, photo } = photoEntry;
        const toCountry = makeCountryLink(toTravel, countryChange);
        const toEntry = makeEntryLink(toCountry, entry);
        const toPhoto = makePhotoLink(toEntry, photo);
        navigate(toPhoto);
      })
      .catch(() => {
        navigate(toTravel);
      });
  }, [navigate, shortName, location.hash]);

  return <>Loading photo...</>;
}
