import { To } from 'react-router-dom';
import { LinkWithIcon } from '../components/LinkWithIcon';
import './TextEntryLink.scss';

export type Props = {
  to: To;
  title: string;
  date: string;
};

export function formatEntryDate(date: string) {
  const rDate = new Date(date);
  rDate.setMinutes(rDate.getMinutes() + rDate.getTimezoneOffset());

  return rDate.toLocaleString('en-GB', {
    dateStyle: 'full',
    timeStyle: 'short',
    hourCycle: 'h23',
  });
}

export function TextEntryLink(props: Props) {
  const { to, title, date } = props;

  return (
    <LinkWithIcon className="link_to_entry" icon="icon_read" to={to}>
      <span className="entry_title">{title}</span>
      <span className="datetime">{formatEntryDate(date)}</span>
    </LinkWithIcon>
  );
}
