import './CountryEntryHeader.scss';
import { To } from 'react-router';
import { CountryChange } from '../clients/entries';
import { LinkWithCountryFlag } from '../components/LinkWithIcon';
import { formatCountryChangeDate } from './CountryLink';
import { User } from '../clients/users';
import { getCountryName } from '../components/countries';
import { makeEntryLink } from '../routes/$travel.$country.$entry._index';
import { makeCountryLink } from '../routes/$travel.$country._index';

export type Props = {
  toTravel: To;
  country: CountryChange;
  user: User;
};

export function CountryEntryHeader(props: Props) {
  const { toTravel, country, user } = props;
  const { date, code: countryCode } = country;

  const countryName = getCountryName(countryCode);
  const countryLink = makeCountryLink(toTravel, country);
  const entryLink = makeEntryLink(countryLink, country);

  return (
    <div className="country-change-header">
      <LinkWithCountryFlag
        to={entryLink}
        countryCode={countryCode}
        showAfter={true}
      >
        {countryCode === 'A0'
          ? countryName
          : user.name + ' is entering ' + countryName}
      </LinkWithCountryFlag>
      {countryCode !== 'A0' && (
        <span className="datetime">{formatCountryChangeDate(date)}</span>
      )}
    </div>
  );
}
