import { Link } from 'react-router-dom';
import { User, UserTravel } from '../clients/users';
import { formatCountryChangeDate } from '../entries/CountryLink';
import { makeTravelLink } from '../routes/$travel';
import './ProfileView.scss';

export type Props = {
  user: User;
  travels: UserTravel[];
};

export function ProfileView(props: Props) {
  const { user } = props;
  return (
    <div className="user-profile">
      <h2>{user.name}'s travels</h2>
      <ul className="user-travels">
        {props.travels.map((travel) => (
          <li key={travel.id}>
            <Link to={makeTravelLink(travel.shortName)}>{travel.title}</Link>
            <span className="datetime">
              created on {formatCountryChangeDate(travel.creationDate)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
