import { To } from 'react-router-dom';

import { CountryChange } from '../clients/entries';
import { CountryPosts } from '../clients/travels';

import { LinkWithIcon } from '../components/LinkWithIcon';
import { TextEntryLink } from './TextEntryLink';

import { makeEntryLink } from '../routes/$travel.$country.$entry._index';
import { makeCountryLink } from '../routes/$travel.$country._index';
import './TextEntries.scss';

export type Props = {
  toTravel: To;
  country: CountryChange;
  entries: CountryPosts['posts'];
  showBackToTop?: boolean;
};

export function TextEntries(props: Props) {
  const { toTravel, country, entries, showBackToTop } = props;
  const toCountry = makeCountryLink(toTravel, country);
  return (
    <ul className="posts">
      {entries !== undefined &&
        entries.map((post) => (
          <li key={post.shortName + post.date}>
            <TextEntryLink to={makeEntryLink(toCountry, post)} {...post} />
          </li>
        ))}
      {showBackToTop && (
        <li>
          <LinkWithIcon className="back_to_top" to={'#top'}>
            Go back to top
          </LinkWithIcon>
        </li>
      )}
    </ul>
  );
}
