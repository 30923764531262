import './EntryNavigationLinks.scss';
import { To } from 'react-router-dom';
import { LinkWithIcon } from '../components/LinkWithIcon';

export type NavigationLink = {
  title: string;
  to: To;
};

export type Props = {
  previous?: NavigationLink;
  next?: NavigationLink;
  children?: React.ReactNode;
};

export function EntryNavigationLinks(props: Props) {
  const { previous, next } = props;
  return (
    <div className="travel_navigation">
      <span className="previous_element">
        {previous && (
          <LinkWithIcon icon="icon_previous" to={previous.to}>
            {previous.title}
          </LinkWithIcon>
        )}
      </span>
      <span className="next_element">
        {next && (
          <LinkWithIcon
            icon="icon_next"
            to={next.to}
            showBefore={false}
            showAfter={true}
          >
            {next.title}
          </LinkWithIcon>
        )}
      </span>
    </div>
  );
}
